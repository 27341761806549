
import { inject, reactive, ref } from "vue";
import { Database } from "vuebase";
import { CartItem, Product } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute } from "vue-router";

export default {
    name: "Order",
    setup() {
        const route = useRoute();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const orderController = reactive(new OrderController(orderId));

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        function launchPrint(): void {
            window.print();
        }

        return {
            orderId,
            order: orderController.order,
            orderController,
            product,
            launchPrint,
        };
    },
};
