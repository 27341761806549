<template>
    <c-panel class="thank-you print-hide">
        <h1>Thank You!</h1>
        <p>
            I sincerely appreciate your business and look forward to printing your
            {{ order.data?.cart.length == 1 ? "image" : "images" }}!
        </p>
        <p>
            You will be receiving an email confirmation of your order shortly. If you have any further questions please
            reach out to me at daniel@cohoprints.com
        </p>
        <a class="print" @click="launchPrint()"><i class="fas fa-print"></i> Print Receipt</a>
    </c-panel>
    <c-panel class="print-only">
        <c-grid>
            <c-grid-item class="width-grow-all">
                <img style="height: 60px" :src="require('@/assets/logo.svg')" />
            </c-grid-item>
            <c-grid-item class="flex f-align-center">
                <div class="text-right text-sm">
                    <span>Coho Prints, LLC</span><br />
                    <span>daniel@cohoprints.com</span><br />
                    <span>(425) 276-1482</span>
                </div>
            </c-grid-item>
        </c-grid>
    </c-panel>
    <c-panel>
        <c-grid v-if="order?.data" class="spacing-xl-all">
            <c-grid-item class="width-100-sm width-50-md width-55-lg width-65-xl">
                <div class="mb-5 pb-5" v-for="(item, index) in order.data.cart" :key="index">
                    <c-grid>
                        <c-grid-item class="width-40-all width-100-lg print-hide">
                            <c-card>
                                <c-image
                                    :image="item.image"
                                    v-if="item.image && product(item)?.category == 'Custom Print'"
                                    :zoom="true"
                                />
                            </c-card>
                            <c-card v-if="product(item)?.category == 'Gallery Print'">
                                <c-image :image="product(item)?.images[0]" :zoom="true" />
                            </c-card>
                        </c-grid-item>
                        <c-grid-item class="width-grow-all">
                            <h2>{{ product(item)?.name }}</h2>
                            <p class="p-0 m-0" v-if="item.size">
                                <span class="text-bold">Size: </span>
                                <span>{{ item.size.width }}&times;{{ item.size.height }}</span>
                            </p>
                            <p class="p-0 m-0" v-for="(option, selection) in item.selections" :key="selection">
                                <span class="text-bold">{{ selection }}: </span>
                                <span>{{ option }}</span>
                            </p>
                            <p class="p-0 m-0">
                                <span class="text-bold">Quantity: </span>
                                <span>{{ item.quantity }}</span>
                            </p>
                        </c-grid-item>
                        <c-grid-item class="width-shrink-all">
                            <h2>${{ item.price.toFixed(0) }}</h2>
                        </c-grid-item>
                    </c-grid>
                </div>
            </c-grid-item>

            <c-grid-item class="width-100-sm width-50-md width-45-lg width-35-xl">
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-box class="p-4">
                            <h1 class="mb-5">Summary</h1>
                            <c-order-invoice :coupon="false" :orderId="order.id" />
                        </c-box>
                    </c-grid-item>
                    <c-grid-item>
                        <c-box class="p-4">
                            <h1 class="mb-5">Shipping</h1>
                            <p>
                                {{ order.data.shipping.address.name }}<br />
                                {{ order.data.shipping.address.street1 }}<br />
                                <template v-if="order.data.shipping.address.street2">
                                    {{ order.data.shipping.address.street2 }}<br />
                                </template>
                                {{ order.data.shipping.address.city }},
                                {{ order.data.shipping.address.state }}
                                {{ order.data.shipping.address.zip }}<br />
                                {{ order.data.contact.email }}
                            </p>
                            <p><span class="text-bold">Method:</span> {{ order.data.shipping.method.service }}</p>
                        </c-box>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, reactive, ref } from "vue";
import { Database } from "vuebase";
import { CartItem, Product } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute } from "vue-router";

export default {
    name: "Order",
    setup() {
        const route = useRoute();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const orderController = reactive(new OrderController(orderId));

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        function launchPrint(): void {
            window.print();
        }

        return {
            orderId,
            order: orderController.order,
            orderController,
            product,
            launchPrint,
        };
    },
};
</script>
<style lang="scss">
@import "~coho-ui/src/styles/variables.scss";

.thank-you {
    background-color: $color-primary;
    color: $color-white;

    h1,
    p,
    a {
        display: block;
        text-align: center;
        color: $color-white;
        margin-left: auto;
        margin-right: auto;
        max-width: 600px;
    }

    h1 {
        margin-top: 32px;
        font-size: 2.5rem;
    }

    p {
        opacity: 0.9;
    }

    .print {
        color: $color-white;
        cursor: pointer;
        font-size: $font-size-sm;
        opacity: 0.7;
        margin-top: 18px;
        text-decoration: underline;
        transition: opacity 200ms ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
}
</style>
